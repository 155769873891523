@font-face {
  font-family: 'Buenos Aires';
  font-style: normal;
  font-weight: 400;
  src: local('Buenos Aires'), local('Buenos-Aires-Regular'), url('./font/BuenosAires-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Buenos Aires';
  font-style: normal;
  font-weight: 600;
  src: local('Buenos Aires Semi Bold'), local('Buenos-Aires-Semi-Bold'), url('./font/BuenosAires-SemiBold.otf') format('opentype');
}
@font-face {
  font-family: 'Buenos Aires';
  font-style: normal;
  font-weight: 700;
  src: local('Buenos Aires Bold'), local('Buenos-Aires-Bold'), url('./font/BuenosAires-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Buenos Aires';
  font-style: normal;
  font-weight: 900;
  src: local('Buenos Aires Black'), local('Buenos-Aires-Black'), url('./font/BuenosAires-Black.otf') format('opentype');
}