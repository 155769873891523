#root, .App {
  height: 100%;
}

.App {
  /*
  display: grid;
  grid-template-rows: auto 1fr auto;
  */
}

.App-logo {
  /*animation: spin infinite 20s linear;*/
  height: 60px;
  pointer-events: none;
  
}

.App-header {
  background-image: linear-gradient(#002e4b, #006fb9);
  /*background-image: url('http://blockfront.se/wp-content/uploads/2018/08/cropped-AdobeStock_201746476_2000x1200-1.jpg');*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  
  font-size: calc(10px + 1.5vmin);
  /*color: #fff;*/
}

.App-link {
  color: #61dafb;
}

.cms-block {

}

.intro-header a {
  color: #fff;
  text-decoration: underline;
}

img {
  max-width: 100%;
}

p {
  font-size: 1.2em;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
